<script>
import CrudFieldSelectorChest from '@/components/forms/crud-fields/CrudFieldSelectorChest.vue'

export default {
  components: { CrudFieldSelectorChest }
}
</script>

<template>
  <crud-details-page
    api="rewards/wheel-milestone-reward"
    disable-top-margin
  >
    <template #form>
      <!-- Label -->
      <crud-field-text
        :sm="8"
        api-field-name="Label"
        required
      />
      <CrudFieldSelectorChest
        :sm="8"
        api-field-name="WheelChest"
      />
      <fields-col
        v-for="i in 6"
        :key="i"
      >
        <section-divider>Milestone {{ i }}</section-divider>
        <crud-field-number
          :sm="5"
          :api-field-name="`Threshold${i > 1 ? i : ''}`"
          label="Threshold"
        />
        <crud-field-selector-files
          :sm="6"
          :api-field-name="`AssetIcon${i > 1 ? i : ''}`"
          label="AssetIcon"
        />
        <fields-col :sm="13">
          <crud-field-selector-skin
            :sm="16"
            :api-field-name="`Skin${i * 2 - 1}`"
            label="Skin1"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`Skin${i * 2 - 1}Amount`"
            label="Skin1 amount"
          />
          <crud-field-selector-skin
            :sm="16"
            :api-field-name="`Skin${i * 2}`"
            label="Skin2"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`Skin${i * 2}Amount`"
            label="Skin2 amount"
          />
        </fields-col>
      </fields-col>
    </template>
  </crud-details-page>
</template>
